//
//
//
//
//
//
//
//
//
//
//
//
//
//


import ResponsiveImage from "~/components/common/ResponsiveImage";
import breakpointsListMixin from '~/mixins/breakpointsList.mixin';

export default {
    name: 'InnerImageZoom',
    components: {ResponsiveImage},
    props: {
        image: {
            type: Object,
            required: true
        },
        scale: {
            type: Number,
            default: 2.5
        },
        minScale: {
            type: Number,
            default: 1
        },
        maxScale: {
            type: Number,
            default: 4
        },
        isFullScreen: {
            type: Boolean,
            default: false
        },
        index: {
            type: Number,
            required: true
        }
    },
    mixins: [
        breakpointsListMixin,
    ],
    data() {
        return {
            currentScale: 1,
            imgProps: {},
            curX: 0,
            curY: 0,
            lastTouchStart: null,
            lastTouch: {
                x: 0,
                y: 0
            }
        };
    },
    computed: {
        isZoomed() {
            return this.currentScale !== 1;
        },
        imageStyle() {
            return {
                scale: this.currentScale,
                translate: `${this.curX}px,${this.curY}px`
            }
        },
        isTabletDown() {
            return this.tabletLandscapeDown;
        },
        currentMoveType() {
            return this.isTabletDown ? 'drag' : 'pan'
        }
    },

    methods: {
        click(e) {
            if (this.isZoomed) {
                this.zoomOut();
            } else {
                this.zoomIn(e.clientX, e.clientY)
            }
        },
        initialMove(pageX, pageY) {
            this.mouseMove({
                pageX: pageX,
                pageY: pageY
            }, true);
        },
        mouseMove(e, hasTransition=false) {
            if (this.isZoomed && !this.isTabletDown) {
                let sizeRatioY = Math.abs(this.$refs.image.clientHeight*this.currentScale - this.$refs.viewport.clientHeight) / this.$refs.viewport.clientHeight;
                let sizeRatioX = Math.abs(this.$refs.image.clientWidth*this.currentScale - this.$refs.viewport.clientWidth) / this.$refs.viewport.clientWidth;

                let mouseRelativeCoordY = e.pageY - this.getPosition(this.$refs.viewport).y - this.$refs.viewport.clientHeight / 2
                let mouseRelativeCoordX = e.pageX - this.$refs.viewport.clientWidth / 2;

                let positionY = (mouseRelativeCoordY * sizeRatioY / this.currentScale) * -1;
                let positionX = (mouseRelativeCoordX * sizeRatioX / this.currentScale) * -1;
                this.curY = positionY ? positionY : 0;
                this.curX = positionX ? positionX : 0;

                this.$refs.image.style.transform = `scale(${this.currentScale}) translate(${this.curX}px,${this.curY}px)`;
                this.$refs.image.style.transition = hasTransition ? 'transform 0.3s ease-out 0s' : '';
            }
        },


        touchstart(e) {
            this.lastTouch.x = e.targetTouches ? e.targetTouches[0].pageX : this.$refs.viewport.clientWidth/2;
            this.lastTouch.y = e.targetTouches ? e.targetTouches[0].pageY : this.$refs.viewport.clientHeight/2;
        },
        touchend(e) {
            if ( !this.isTabletDown ) {
                return
            }
            if (this.isZoomed) {
                this.zoomOut();
            } else {
                this.zoomIn(e.clientX, e.clientY)
            }
        },
        initialDragMove() {
            if (this.isZoomed) {
                this.dragMove({
                    pageX:0,
                    pageY:0,
                    preventDefault: () => {},
                    stopPropagation: () => {}
                }, true);
            }
        },
        dragMove(e, hasTransition=false) {
            if ( !this.isZoomed ) {
                return
            }

            e.preventDefault();
            e.stopPropagation();

            let maxX = (this.$refs.image.clientWidth*this.currentScale - this.$refs.viewport.clientWidth)/2,
                maxY = (this.$refs.image.clientHeight*this.currentScale - this.$refs.viewport.clientHeight)/2;

            this.curX = this.curX - (e.targetTouches ? this.lastTouch.x - e.targetTouches[0].pageX : e.pageX);
            this.curY = this.curY - (e.targetTouches ? this.lastTouch.y - e.targetTouches[0].pageY : e.pageY);

            this.curX = -maxX > this.curX ? -maxX : maxX < this.curX ? maxX : this.curX;
            this.curY = -maxY > this.curY ? -maxY : maxY < this.curY ? maxY : this.curY;

            this.lastTouch.x = e.targetTouches ? e.targetTouches[0].pageX : this.$refs.viewport.clientWidth/2;
            this.lastTouch.y = e.targetTouches ? e.targetTouches[0].pageY : this.$refs.viewport.clientHeight/2;

            this.$refs.image.style.transform = `translate3d(${this.curX}px, ${this.curY}px, 0px) scale(${this.currentScale})`;
            this.$refs.image.style.transition = hasTransition ? 'transform 0.3s ease-out 0s' : '';
        },


        zoomIn(pageX, pageY) {
            const initialMove = this.currentMoveType === 'drag' ? this.initialDragMove : this.initialMove;
            this.currentScale = this.scale;
            initialMove(pageX, pageY);
            this.$emit('zoomToggle', true);
        },
        zoomOut() {
            this.currentScale = 1;
            this.curX = 0;
            this.curY = 0;

            this.$refs.image.style.transform = '';
            this.$refs.image.style.transition = 'transform 0.3s ease-out 0s';
            setTimeout(() => {
                this.$emit('zoomToggle', false);
            },0)
        },

        isDoubleTap(event) {
            let fingers = event.touches.length;
            var time = (new Date()).getTime();
            if (fingers > 1) {
                this.lastTouchStart = null;
            }
            if (time - this.lastTouchStart < 600) {
                return true;
            }
            this.lastTouchStart = time;
            return false;
        },
        getPosition  (el) {
            let xPos = 0;
            let yPos = 0;
            while (el) {
                if (el.tagName === "BODY") {
                    let xScroll = el.scrollLeft || document.documentElement.scrollLeft;
                    let yScroll = el.scrollTop || document.documentElement.scrollTop;
                    xPos += (el.offsetLeft - xScroll + el.clientLeft);
                    yPos += (el.offsetTop - yScroll + el.clientTop);
                } else {
                    xPos += (el.offsetLeft - el.scrollLeft + el.clientLeft);
                    yPos += (el.offsetTop - el.scrollTop + el.clientTop);
                }
                el = el.offsetParent;
            }
            return {
                x: xPos,
                y: yPos
            };
        },

        mouseleave () {
            if (this.isZoomed && !this.isFullScreen) {
                this.zoomOut();
            }
        }
    }
};

