//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import ResponsiveImage from "@/components/common/ResponsiveImage";
import breakpointsListMixin from '~/mixins/breakpointsList.mixin';
import FullScreenBtn from "@/components/common/buttons/FullScreenBtn";
import InnerImageZoom from "@/components/common/InnerImageZoom";

export default {
    name: "ProductSlider",
    components: {
        FullScreenBtn,
        InnerImageZoom,
        VueSlickCarousel,
        ResponsiveImage,
    },
    props: {
        product: {
            type: Object,
            default: () => {}
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    mixins: [
        breakpointsListMixin,
    ],
    data() {
        return {
            settings: {
                arrows: false,
                dots: false,
                variableWidth: false,
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1
            },
            activeSlide: 0,
            zoomActive: false,
            isFullScreen: false,
        }
    },
    computed: {
        imageZoom() {
            return !this.isDesktop ? 3 : 2.5;
        },
        isDesktop() {
            return this.tabletLandscapeUp;
        },
        isMobile() {
            return this.mobile;
        },
        imagesFullList(){
            return (this.product?.imagesFullList ?? null)
        },
        imageForFullScreen() {
            return this.imageUrl(this.imagesFullList[this.activeSlide], true);
        },
        body() {
            return document ?  (document?.body ?? null) : null;
        },
    },
    methods: {
        showNext() {
            this.$refs.carousel.next()
        },
        showPrev() {
            this.$refs.carousel.prev()
        },
        chooseSlide(index) {
            this.$refs.carousel.goTo(index)
        },
        beforeChange(oldIndex, newIndex) {
            this.$refs.zoomComponent.zoomOut();
            this.activeSlide = newIndex;
        },
        zoomStatus(flag) {
            this.zoomActive = flag;
            if (!flag && this.isFullScreen) {
                this.changeFullScreen(false)
            }
        },
        changeFullScreen(flag) {
            this.isFullScreen = flag;
            if (flag) {
                this.body && this.body.classList.add('v-popup-open');
                this.isMacOs && this.body.classList.add('isMacOs');
                this.$refs.fullScreenImage.zoomIn(0, 0);
            } else {
                this.body && this.body.classList.remove('v-popup-open');
                this.$refs.fullScreenImage.zoomOut();
            }
        },
        imageUrl(image, zoom = false) {
            let imageSize = this.zoomActive || zoom ? ImageSizes.cardMainZoom : ImageSizes.cardMain,
                imageQuality =  this.zoomActive || zoom ? 100 : 90;
            return ImageHelper.getMediaStructure(image.id, AppConfig.image.fileExtensions.productCard, image.title, image.alt, imageSize, imageQuality)
        },
        imageThumbnailUrl(image) {
            return ImageHelper.getMediaStructure(image.id, AppConfig.image.fileExtensions.productCard, image.title, image.alt, ImageSizes.cardThumbnail, 60)
        },
    },
}
