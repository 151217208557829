
import icons from "./components/icons";
import common from "./mixin/common";

export default {
    name: "FullScreenBtn",
    mixins: [common],
    props: {
        active: {
            type: Boolean,
            default: false
        }
    },
    render: function (createElement, {data, props, listeners}) {
        const isLink = props.to && props.to.trim();
        const wrapperTag = isLink ? 'nuxt-link' : 'button';

        const iconElement = createElement('div', {
            domProps: {innerHTML: icons[props.active ? 'close' : 'fullScreen']},
            class: 'button-full-screen__icon'
        });
        const button = createElement(wrapperTag, {
            class: [
                'button-full-screen__btn',
                {'button-full-screen__btn--active': props.active},
                {'button-full-screen__btn--disabled': props.disabled}
            ],
            attrs: {
                to: isLink && props.to,
                disabled: !isLink && props.disabled,
                'aria-label': props.ariaLabel
            },
            on: {
                click: listeners.click || (() => {})
            }
        }, [iconElement]);
        return createElement('div', {
            class: [
                'button-full-screen',
                data.staticClass
            ],
        }, [button]);
    }
}
